import React, { useEffect, useState } from 'react';

import C08V3PeopleOrg from './../../../Components/Organisms/Personas/C08/v3'

import {URL_WEB_SERVICE_LOCAL_STORAGE} from '../../../constants/general'
import { CPlanService, CategoriesService, ChannelsService,} from '../../../actions/HttpCalls'
import { HttpClient } from '../../../actions/HttpClient'

const httpClient = new HttpClient();
const categoriesService = new CategoriesService(httpClient);
const channelsService = new ChannelsService(httpClient);
const cPlanService = new CPlanService(httpClient);


function HomePeopleContainerUi(props) {

    const [dataCategoriasBk, setDataCategoriasBk] = useState([])
    const [dataChannelsBk, setDataChannelsBk] = useState([])
    const [dataPlanChannelsBk, setDataPlanChannelsBk] = useState([])

    const getValuesDefault = async () => {
        if( URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1 ){

            const channels = await channelsService.getChannelsItems()
            setDataChannelsBk(channels)

            const categories = await categoriesService.getCategoriesItems()
		    setDataCategoriasBk(categories)

            const cplanes = await cPlanService.getCPlanItems()
		    setDataPlanChannelsBk(cplanes)

            console.log("channels", channels)
            console.log("categories", categories)
            console.log("cplanes", cplanes)
        }
	}

    useEffect(() => {
        localStorage.getItem('url_detalle_default') ? getValuesDefault() : null;
		// eslint-disable-next-line
	}, [])

    return (
        <>
        {
            URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1  ?
            <>
                {
                    dataChannelsBk.length > 0 ?
                    <C08V3PeopleOrg
                        dataChannelsBk={dataChannelsBk}
                        dataCategoriasBk={dataCategoriasBk}
                        dataPlanChannelsBk={dataPlanChannelsBk}
                    />
                    :null
                }
            </>
            :
            <C08V3PeopleOrg
                dataChannelsBk={dataChannelsBk}
                dataCategoriasBk={dataCategoriasBk}
                dataPlanChannelsBk={dataPlanChannelsBk}
            />

        }
        </>
    )
}

export default HomePeopleContainerUi
