import { HttpClient } from "./HttpClient";
import { GET_CATEGORIES, GET_CHANNELS, GET_COVERAGE_TYPES, GET_PAQUETES, GET_CPLANES } from "../constants/endPoints"

export class CategoriesService {
    constructor(http = HttpClient) {
        this.http = http
    }

    async getCategoriesItems() {
        const { data } = await this.http.post(GET_CATEGORIES)
        return data
    }
}

export class ChannelsService {
    constructor(http = HttpClient) {
        this.http = http
    }

    async getChannelsItems() {
        const { data } = await this.http.post(GET_CHANNELS)
        return data
    }
}

export class CoverageTypeService {
    constructor(http = HttpClient) {
        this.http = http
    }

    async getCoverageTypeItems() {
        const { data } = await this.http.post(GET_COVERAGE_TYPES)
        return data
    }
}

export class PaqueteService {
    constructor(http = HttpClient) {
        this.http = http
    }

    async getPaqueteItems() {
        const { data } = await this.http.post(GET_PAQUETES)
        return data
    }
}

export class CPlanService {
    constructor(http = HttpClient) {
        this.http = http
    }

    async getCPlanItems() {
        const { data } = await this.http.post(GET_CPLANES)
        return data
    }
}