import React from 'react'

import Ui from './index.ui'

function ModulosPeopleContainer() {
    
    return (
        <Ui />
        )
    }

export default ModulosPeopleContainer
