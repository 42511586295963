import React, {
    useEffect,
    useState,
    // useCallback, 
    // Fragment, 
    // useRef
} from 'react'
import axios from 'axios'
import { v4 as uuid } from 'uuid'
import NumberFormat from 'react-number-format';

import $ from "jquery"
// import 'slick-carousel/slick/slick.min'

import Slider from "react-slick"
import SliderTabs from '../../../Molecules/SliderTabs'

// Hooks
// import useWindowSize from 'Hooks/useWindowSize'

// Mol
// import PlayPlanCardMol from '../../../Molecules/Cards/Plan/Play'
import General2PlanCardMol from './../../../Molecules/Cards/Plan/General2'
// import SimplePlanCardMol from '../../../Molecules/Cards/Plan/Simple'

// Sass
import './../../../../Sass/includes/Components/modules/C08.sass'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function C08PeopleOrg(props) {
    const {
        dataChannelsBk,
        dataCategoriasBk,
        dataPlanChannelsBk
    } =props
    const [dataCPlanes, setdataCPlanes] = useState([])
    const [tipoPlanConcat, setTipoPlanConcat] = useState('')
    const [datagenerateID, setDatagenerateID] = useState(generateID())
 
    const tabs = dataCPlanes?.length ? dataCPlanes.map((item, index) => (
        {
            title: item.nameTab,
        }
    )) : []

    // const [tabMobilOpen, setTabMobilOpen] = useState(false)
    const [tabSelected, setTabSelected] = useState({})

    const [settingsSlider] = useState({
		autoplay: false,
		autoplaySpeed: 7000,
		speed: 600,
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: true,
		arrows: false,
        centerMode: false,
		infinite: false,
		responsive: [
			// { 
			//     breakpoint: 100000, 
			//     settings: 'unslick' 
			// },
			// { 
			//     breakpoint: 640, 
			//     settings: {
			//         autoplay: true,
			//         autoplaySpeed: 7000,
			//         speed: 600,
			//         slidesToShow: 1,
			//         slidesToScroll: 1,
			//         dots: true,
			//         arrows: false,
			//     }
			// },

			{
				breakpoint: 1102,
				settings: {
					slidesToShow: 2,
                    centerMode: false
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					centerMode: true,
					centerPadding: '28px'
				}
			}
		]
	})


    async function getCPlanes() {
        let tipoPlanConcat = await getTipoPlan()
        // eslint-disable-next-line
        setTipoPlanConcat( tipoPlanConcat )
        console.log("dataCPlanes**************: ")
        let url_servicio_web = localStorage.getItem('url_servicio_web')
        let tabs_data = localStorage.getItem('tabs_data'+tipoPlanConcat) ? localStorage.getItem('tabs_data'+tipoPlanConcat).split("|") : []
        console.log("tabs_data"+tipoPlanConcat, tabs_data.length, tabs_data)
        let configCPlanes = {}
        let response = [], newdataPlanes = [], setKeyTab = false;
        if (tabs_data?.length > 0) {
            for (let i = 0; i < tabs_data.length; i++) {
                let tb_info = tabs_data[i].split(",");
                console.log("map_tabsData", tabs_data[i].split(","))
                let Request = tb_info[1] === "CAT" ? "getPlanesDestacadosXCategoria" : tb_info[1] === "FAM" ? "getPlanesDestacadosXFamilia" : "getPlanesDestacadosXServicio";
                // let tipo = tb_info[1] === "CAT" ? "CAT" : tb_info[1] === "FAM" ? "FAM" : "SER";
                configCPlanes = {
                    method: 'POST',
                    url: url_servicio_web + Request,
                    data: {
                        fc_tipo_plan: tb_info[6],
                        fi_servicio: Number.isInteger(parseInt(tb_info[0])) ? parseInt(tb_info[0]) : 0,
                        fc_tipo: tb_info[5],
                        fc_familia: tb_info[0]
                    },
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': "application/json"
                    },
                }
                try {
                    response = await axios(configCPlanes)
                    if (setKeyTab === false) {
                        setTabSelected({ title: tb_info[2] })
                        setKeyTab = true
                    }
                    newdataPlanes.push({ nameTab: tb_info[2], filterOption: tb_info[3], FilterId: tb_info[4], planesDestacados: response.data, fi_servicio: Number.isInteger(parseInt(tb_info[0])) ? parseInt(tb_info[0]) : 0})
                    console.log("response- getCPlanes try------------------------->", response.data, tabSelected, tabSelected?.length)
                }catch{
                    console.log("error get data getCPlanes", tabSelected, tabSelected?.length)
                }
            }
            console.log("setdataCPlanes response- getCPlanes------------------------->", newdataPlanes)
            setdataCPlanes(newdataPlanes)
        }
    }
    
    function generateID() {
	    return "_" + Math.random().toString(36).substr(2, 9);
    }
    async function getTipoPlan(){
        let tipoPlan = $("#c08Wrap"+datagenerateID).parent().parent().attr('tipoplan')
        console.log("getTipoPlan", "tipoPlan:", tipoPlan, "datagenerateID:",datagenerateID)
        // if ( tipoPlan !== undefined && tipoPlan === "PLN" ){
        //     return "_PLN"
        // }
        // if ( tipoPlan !== undefined && tipoPlan === "PAQ" ){
        //     return "_PAQ"
        // }
        // if ( tipoPlan !== undefined && tipoPlan === "SAD" ){
        //     return "_SAD"
        // }
        return ""
    }
    
    useEffect(() => {
        let newid = datagenerateID
        if ( datagenerateID === '' ){
            newid = generateID()
            setDatagenerateID(newid)
        }
        localStorage.removeItem('preFilter')
        console.log('ready', newid)
        getCPlanes();
        //eslint-disable-next-line
    }, [])

  

    
    const setColsClass = (size) => (`
        ${size === 3 ? 'cols3Count' : ''}
        ${size === 2 ? 'cols2Count' : ''}
        ${size === 1 ? 'cols1Count' : ''}
    `)

    const _renderCards = (items, fi_servicio) => (
        items &&
            items.length > 0
            ?
            items.map((item, index) => {
                return (
                    <div key={uuid()} className="c08BodyCardWrap" data-idplan={item.fi_PLAN}>
                        <General2PlanCardMol
                            key={uuid()}
                            // {...item}
                            // key={uuid()}
                            title={item.fc_TEXTO_CAJA}
                            titlestyle={item.fc_COLOR_CAJA.toLowerCase()}
                            name={item.fc_NOMBRE}
                            // changeCompare={handleChangeCompare}
                            fi_PLAN={item.fi_PLAN}
                            fi_ID_TELEFONIA={item.fi_ID_TELEFONIA}
                            fi_ID_TELEVISION={item.fi_ID_TELEVISION}
                            fi_ID_INTERNET={item.fi_ID_INTERNET}
                            fi_CATEGORIA={item.fi_CATEGORIA}
                            fc_TIPO_DISPONIBILIDAD={item.fc_TIPO_DISPONIBILIDAD}
                            fc_TEXTO_CAJA={item.fc_TEXTO_CAJA}
                            fc_COLOR_CAJA={item.fc_COLOR_CAJA}
                            fc_NOMBRE={item.fc_NOMBRE}
                            fc_DESCRIPCION={item.fc_DESCRIPCION}
                            fi_NUMERO_CARACTERISTICAS_MOSTRAR={item.fi_NUMERO_CARACTERISTICAS_MOSTRAR}
                            fc_TITULO_CARACTERISTICAS_AGRUPADAS={item.fc_TITULO_CARACTERISTICAS_AGRUPADAS}
                            fi_PRECIO_PLAN={item.fi_PRECIO_PLAN.toFixed(2)}
                            fi_PRECIO_TELEFONIA_ENPS={item.fi_PRECIO_TELEFONIA_ENPS}
                            fi_PRECIO_TELEVISION_ESPN={item.fi_PRECIO_TELEVISION_ESPN}
                            fi_PRECIO_INTERNET_ESPS={item.fi_PRECIO_INTERNET_ESPS}
                            fc_COLOR_PRECIO={item.fc_COLOR_PRECIO}
                            fc_ETIQUETA_PRECIO_TACHADO={item.fc_ETIQUETA_PRECIO_TACHADO}
                            fc_PRECIO_TACHADO={<NumberFormat thousandSeparator={","} decimalSeparator={"."} displayType={'text'} value={item.fc_PRECIO_TACHADO.includes(".") ? item.fc_PRECIO_TACHADO : item.fc_PRECIO_TACHADO+".00" } />}
                            Discount={item?.fc_PRECIO_TACHADO > 0 && parseInt(item.fc_PRECIO_TACHADO) !== parseInt(item.fi_PRECIO_PLAN) ? <NumberFormat displayType={'text'} value={(100 - (item.fi_PRECIO_PLAN*100)/item.fc_PRECIO_TACHADO).toFixed(0)}/> : null}
                            fc_NOTA_DEBAJO_PRECIO={item.fc_NOTA_DEBAJO_PRECIO}
                            fc_TEXTO_DETALLE={item.fc_TEXTO_DETALLE}
                            fc_URL_DETALLE={item.fc_URL_DETALLE}
                            fc_TARGET_DETALLE={item.fc_TARGET_DETALLE}
                            fi_BAN_DETALLE={item.fi_BAN_DETALLE}
                            fc_OPCION_CONTRATACION={item.fc_OPCION_CONTRATACION}
                            fc_TEXTO_CONTRATAR={item.fc_TEXTO_CONTRATAR}
                            fc_URL_CONTRATAR={item.fc_URL_CONTRATAR}
                            fc_TARGET_CONTRATAR={item.fc_TARGET_CONTRATAR}
                            fi_BAN_CONTRATAR={item.fi_BAN_CONTRATAR}
                            fi_ORDEN={item.fi_ORDEN}
                            fi_ACTIVO={item.fi_ACTIVO}
                            fi_ID_DASHBOARD={item.fi_ID_DASHBOARD}
                            fi_BAN_ENPN={item.fi_BAN_ENPN}
                            fi_BAN_ENPS={item.fi_BAN_ENPS}
                            fi_BAN_ESPN={item.fi_BAN_ESPN}
                            fi_BAN_ESPS={item.fi_BAN_ESPS}
                            fc_ETIQUETA_PROMOCION_ATP={item.fc_ETIQUETA_PROMOCION_ATP}
                            fi_PRECIO_AHORRO={item.fi_PRECIO_AHORRO}
                            fc_ETIQUETA_PERIODO={item.fi_PRECIO_PLAN > 0 ? item.fc_ETIQUETA_PERIODO : null}
                            fc_TITULO_PROMO={item.fc_TITULO_PROMO}
                            Caracteristicas={item.caracteristicas}
                            caracteristicasPromocion={item.caracteristicasPromocion}
                            Acordeon={item.acordeon}
                            lstPlanContratacionxPlan={item.lstPlanContratacionxPlan}
                            fi_INCLUIR_SECCION={item.fi_INCLUIR_SECCION}
                            fc_ETIQUETA_ROJA={item.fc_ETIQUETA_ROJA}
                            fc_TITULO_SECCION2={item.fc_TITULO_SECCION2}
                            fc_DESCRIPCION_SECCION={item.fc_DESCRIPCION_SECCION}
                            fc_TEXTO_BOTON={item.fc_TEXTO_BOTON}
                            fc_TARGET_BOTON={item.fc_TARGET_BOTON}
                            fc_URL_BOTON={item.fc_URL_BOTON}
                            fc_DESCRIPCION_OFERTA={item.fc_DESCRIPCION_OFERTA}
                            fc_PLAN_ICONO={item.fc_PLAN_ICONO}
                            fc_NOTA_DETALLE_PROMOCION={item?.fc_NOTA_DETALLE_PROMOCION}
                            fi_SERVICIO={item?.fi_SERVICIO}
                            dataChannelsBk={dataChannelsBk}
                            dataCategoriasBk={dataCategoriasBk}
                            dataPlanChannelsBk={dataPlanChannelsBk}
                        />
                    </div>
                )
            })
            : <div>Cargando... </div>
    )
    let TXT_BOTON_FOOTER = dataCPlanes.filter((plan, index) => {
        if (plan.nameTab === tabSelected.title) {
            console.log("tabSelected:-----", plan?.planesDestacados)
            return plan
        } else {
            return null
        }
        // return plan.nameTab === tabSelected.title;
    })

    const tabsData = dataCPlanes?.length ? dataCPlanes.map((item, index) => (
        {
            tabTitle: item.nameTab,
			tabContent:
            <div key={uuid()} className={`genTabsCont ${tabs[index]?.title === tabSelected?.title ? 'active' : ''}`} id="tab-1">
                <div className="c08Body">
                    <h2>{item?.planesDestacados[0]?.fc_TITULO_SECCION}</h2>
                    <Slider
                        key={uuid()}
                        {...settingsSlider}
                        // asNavFor={slider1}
                        // ref={el => c08BodyCardsRef.current[index] = el}
                        className={`c08BodyCards2 autoCenter ${setColsClass(item?.planesDestacados.length)}`}
                    >
                        {_renderCards(item?.planesDestacados, item.fi_servicio)}
                    </Slider>

                </div>
            </div>
            
        }
    )) : []

    console.log("tabSelected v3:", tabSelected, "tabs:", tabs, "dataCPlanes.....: ", dataCPlanes, "TXT_BOTON_FOOTER:", TXT_BOTON_FOOTER)
    console.log("tipoPlanConcat v3:", tipoPlanConcat)
    console.log("tabsData v3-------------->", tabsData, tabSelected)

    return (
        <section className="c08Wrap" id={'c08Wrap'+datagenerateID}>
            <div className="container">
                {
                    tabsData.length
                    ?
                    <SliderTabs
                        dataCPlanes={dataCPlanes}
                        tabsData={
                            tabsData
                        }
                    />
                    :null
                }
            </div>
        </section>
    )
}

C08PeopleOrg.defaultProps = {

}
C08PeopleOrg.propTypes = {

}

export default C08PeopleOrg
